import { computed, Injectable, signal } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LoadingOverlayService {
  private _isLoading = signal(false);

  public loading = computed(() => this._isLoading());

  public setLoading(isLoading: boolean): void {
    this._isLoading.set(isLoading);
  }
}
